﻿@mixin link-variant($color, $hover-color){

     > a {
         color: $color;

      &:hover,
      &:focus {
        text-decoration: none;
        color: $hover-color;
      }
    }

    &.current > a {
         color: $hover-color;
         font-weight: $font-weight-regular;
    }

}