﻿
.list-tiles{
    margin-bottom: 0;
}

.tile-item{
    padding: 50% 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;

   background-repeat: no-repeat;
   background-position: center center;
   background-size: cover;
   


    .tile-item-content{
        position:absolute;
        top:0;
        left:0;
        bottom: 0;
        right: 0;
        background-color: $color-grey-trans;
        padding: $box-unit/2;

        opacity:0;

        transition: opacity 0.5s linear;


        h2{
            font-size: $font-size-h4;
            margin-top: 0;
        }
    }

    &:hover, & :active{ 
        .tile-item-content{
         opacity: 1;
         }
    }
}



.col-sm-6 .tile-item{
    padding: 49.98% 0;
}

.btn-more .btn-link{
    padding-top: 0;
    padding-bottom: 0;
}


.row-grid{

    @include make-row($row-grid-gutter);

    *[class*="col-"]{
        padding-left: $row-grid-gutter/2;
        padding-right: $row-grid-gutter/2;
        margin-bottom:$row-grid-gutter;
    }

}


.push-grid{
    margin-bottom:$row-grid-gutter;
}
 .media-item{
    position: relative;
    width: 100%;
    margin-bottom: $box-unit;
 }


 .cycle-slideshow{
     margin-bottom: $box-unit;
      overflow: hidden;
 }

 header{ 
     .cycle-slideshow,
     .media-item{
        margin-bottom: 0;

    }
 }


.cycle-prev,
.cycle-next{
    position: absolute;
    z-index: 110;
    width: 25px;
    height: 44px;
    background: transparent url('../content/images/icon-arrows.svg') no-repeat left top;
    cursor: pointer;
    top: 40%;

    &:hover{
            background-position: bottom;
    }

}
.cycle-slide{
    margin-bottom: 0;
}
.cycle-prev{
    background-position: left top;
    left: $box-unit;

        &:hover{
            background-position: left bottom;
    }
}
.cycle-next{
    background-position: right top;
    right: $box-unit;
       &:hover{
            background-position: right bottom;
    }
}

.media-description{
    margin-bottom: 0;
    background-color: $color-grey;
    padding: $box-unit /2 ;
}




.testimonial{
    padding: $box-unit * 2;
    background-color: $color-grey;
    text-align: center;

    font-weight: 300;
   

    h4{
         color: $brand-primary;
    }

    .body-copy{
       
        margin-bottom: $box-unit*3;
    }
}






@media(min-width:$screen-sm-min){

    .testimonial{
         padding: $box-unit * 4;
         font-size: $font-size-h2;

          .body-copy{
              line-height:1.2em;

          }

    }

    .safari{
    .testimonial{
        font-weight: 100;
    }
}


    .tile-item{

    .tile-item-content{

        h2{
            font-size: $font-size-h3;

        }
    }

}

}

@media (min-width: $screen-lg-min)
{
    .container .row-grid{
     @include make-row($row-grid-gutter);
}

}



