﻿body {
    font-family: $font-stack-primary;
    font-weight: $font-weight-light;
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4{
    font-weight: $font-weight-light;
    color: $brand-primary;
}

h1,.h1,h2,.h2{
   margin: $box-unit 0; 
}



.line-h1{
    line-height: $font-size-h1;
    margin-top: $box-unit;
}

strong{
    font-weight: normal;
}

.zone-aside-reveal .h1{
    font-size: 42px;
}

a:hover, a:focus {
    text-decoration: none;
}

p{
    margin-bottom: $box-unit;
}

.content-item .body-copy{

    margin-bottom: $box-unit*3;
    max-width: $container-large-desktop * 0.75;

    p{
    //color: $brand-primary;
   // font-size: $font-size-h4;
}

}

.content-item header{

    h1{
        font-size: $font-size-h4;
        font-family: Arial;
    }

}

@media (min-width: $screen-sm-min){
    .content-item .body-copy{

    p{
  
    font-size: $font-size-h4;
}

}

}