﻿ul.menu {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        padding: 0 $box-unit;

        @include link-variant($color-black, $brand-primary);

        a {
            line-height: $dim-header-height * 0.75;
        }
    }
}


.icon-menu {
    width: 25px;
    height: 25px;
    display: inline-block;
    background-color: $color-grey;
    margin-top: 21px !important;
}

.icon-bar {
    background-color: $brand-primary;
    border-radius: 1px;
    display: block;
    height: 2px;
    margin-bottom: 5px;
}

@media (min-width: $screen-sm-min) {

    ul.menu {

        li {
            display: inline-block;
        }
    }
}

$link-size: 48px;

ul.menu-social-menu {

    li {
        padding: 0 7px;
        display: inline-block;

        &.first {
            padding-left: 0;
        }
    }
}

a.icon-link {
    display: inline-block;
    width: $link-size;
    height: $link-size;
    line-height: $link-size;
    text-align: center;
    background-color: $color-grey-d;
    color: #fff;
    border-radius: $link-size/2;
    font-size: 0;


    i {
        font-size: 30px;
        color: #fff;
        line-height: $link-size;
    }

    &:hover {
        background-color: $brand-primary;
    }
}

.menu-subnav {

    ul.menu-main-menu {
        margin-top: -$box-unit/3;

        > li {
            display: block;
            padding: $box-unit/3 0;
            font-size: $font-size-h4;

            @include link-variant($brand-primary, $brand-primary);

            &.current a {
                font-weight: bold;
            }

            a {
                line-height: 1.2em;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {

    .menu-subnav {
        ul.menu-main-menu {
            display: table;
            width: 100%;
            //  table-layout: fixed;
            li {
                display: inline-block;
                width: 50%;
                font-size: $font-size-base;
                padding: 0.25em 0;
                vertical-align: top;

                a {
                    line-height: 1em;
                }
            }

            li:nth-child(2n+1) {
                border-right: 1px solid $brand-primary;
            }

            li:nth-child(2n) {
                padding-left: 1em;
            }
        }
    }
}
