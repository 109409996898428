﻿$color-grey: #f2f2f2;
$color-grey-d: #D4D3D3;
$color-grey-trans: transparentize($color-grey, 0.1);
$color-black: #000;

$font-stack-primary: Lato, Helvetica, sans-serif;

$dim-header-height: 80px;

$font-weight-light: 300; 
$font-weight-regular: 400;
  
$box-unit: 14px;
$row-grid-gutter: 14px;