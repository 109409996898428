﻿body {
    overflow-x: hidden;
}

.padding {
    padding: $box-unit;
}



#layout-foreground {
    position: relative;
    z-index: 10;
    background-color: #fff;
}


#layout-header { 
    background-color: $color-grey;
    margin-bottom: $box-unit;

    h2, .menu a {
        margin: 0;
        line-height: $dim-header-height * 0.75;
    }

    p {
        margin: 0;
    }

    h2 a,
    h2 a:hover,
    h2 a:active {
        color: $text-color;
    }
}

    #layout-content-aside{
    padding-bottom: $box-unit;
}

#layout-footer {
   // background-color: $color-grey;
    padding: $box-unit 0;

    p {
       // display: inline-block;
       // vertical-align: middle;
       margin: 0 0 $box-unit 0;
    }


}

.valign-middle {
    display: inline-block;
    vertical-align: middle;
}

#layout-aside-reveal {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 300px;


    .zone-aside-reveal {
        position: relative;


        article {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 5;
            opacity: 0;
            background-color: #fff;
            -moz-transform: translateY(-110%);
            -ms-transform: translateY(-110%);
            -o-transform: translateY(-110%);
            -webkit-transform: translateY(-110%);
            transform: translateY(-110%);

            &.active {
                z-index: 10;
                opacity: 1;
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }


            &:first-child {
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                -webkit-transform: translateY(0);
                transform: translateY(0);
                z-index: 5;
            }
        }
    }
}

#layout-wrapper.aside-reveal {
    #layout-aside-reveal.animate {
        .zone-aside-reveal {

            article {
                -moz-transition: 0.5s ease-in-out;
                -o-transition: 0.5s ease-in-out;
                -webkit-transition: 0.5s ease-in-out;
                transition: 0.5s ease-in-out;
            }
        }
    }
}

#layout-foreground {
    -moz-transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1) 0s;
    -o-transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1) 0s;
    -webkit-transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1) 0s;
    transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1) 0s;
}

.aside-reveal {
    #layout-foreground {
        -moz-transform: translate3d(-300px, 0px, 0px);
        -ms-transform: translate3d(-300px, 0px, 0px);
        -o-transform: translate3d(-300px, 0px, 0px);
        -webkit-transform: translate3d(-300px, 0px, 0px);
        transform: translate3d(-300px, 0px, 0px);
    }
}


.pager {
    margin: $box-unit 0;
    text-align: right;

    li > a {
        padding-top: 0;
        padding-bottom: 0;
        font-weight: $font-weight-regular;
    }
}

@media (min-width: $screen-sm-min) {

    #layout-content-aside{
    padding-top: $box-unit;
}

    .text-right-sm {
        text-align: right;
    }



    #layout-header {
        h2, .menu a {
            line-height: $dim-header-height;
        }
    }
}
